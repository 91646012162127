import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/infomateriaal-diaree/patientenbrochure/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/materiel-dinformation-diarrhee/brochure-patient/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="8be34576945d37e6ab4db1f22c6e48a7121c66fb"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="parent-active last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <div className="widget">
              <div className="inner">
                <h4>Recepten</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_rezepte.jpeg"
                    width="170"
                    height="102"
                    alt="Recepten"
                  />
                </div>
                <p>
                  Een evenwichtige voeding is een vereiste voor je gezondheid.
                  Hier vind je recepten die lekker en gezond zijn.
                </p>
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/recepten-voor-een-evenwichtige-voeding/">
                  Meer informatie
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-48">
            <h1>Patiëntenbrochure</h1>
            <p>
              <input
                id="brochure-folder"
                type="hidden"
                defaultValue="patientenbrochure"
              />
            </p>
            <div className="magazine-viewport">
              <div className="container">
                <div className="magazine">
                  <div className="next-button" ignore="1">
                    &nbsp;
                  </div>
                  <div className="previous-button" ignore="1">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnails">
              <div>
                <ul>
                  <li className="i">
                    <img
                      className="page-pat1"
                      src="/assets/files/pages/imodium_brochure/0001.jpg"
                      style={{
                        width: "76px",
                        height: "154px",
                        "max-width": "none"
                      }}
                    />{" "}
                    <span>1</span>
                  </li>
                  <li className="d">
                    <img
                      className="page-2"
                      src="/assets/files/pages/imodium_brochure/0002.jpg"
                      style={{
                        width: "76px",
                        height: "154px",
                        "max-width": "none"
                      }}
                    />{" "}
                    <img
                      className="page-3"
                      src="/assets/files/pages/imodium_brochure/0003.jpg"
                      style={{
                        width: "76px",
                        height: "153px",
                        "max-width": "none"
                      }}
                    />{" "}
                    <span>2-3</span>
                  </li>
                  <li className="d">
                    <img
                      className="page-4"
                      src="/assets/files/pages/imodium_brochure/0004.jpg"
                      style={{
                        width: "76px",
                        height: "154px",
                        "max-width": "none"
                      }}
                    />{" "}
                    <img
                      className="page-5"
                      src="/assets/files/pages/imodium_brochure/0005.jpg"
                      style={{
                        width: "76px",
                        height: "153px",
                        "max-width": "none"
                      }}
                    />{" "}
                    <span>4-5</span>
                  </li>
                  <li className="i">
                    <img
                      className="page-6"
                      src="/assets/files/pages/imodium_brochure/0006.jpg"
                      style={{
                        width: "76px",
                        height: "154px",
                        "max-width": "none"
                      }}
                    />{" "}
                    <img
                      className="page-7"
                      src="/assets/files/pages/imodium_brochure/0007.jpg"
                      style={{
                        width: "76px",
                        height: "153px"
                      }}
                    />
                    <span>6-7</span>
                  </li>
                  <li className="i">
                    <img
                      className="page-8"
                      src="/assets/files/pages/imodium_brochure/0008.jpg"
                      style={{
                        width: "76px",
                        height: "154px"
                      }}
                    />
                    <img
                      className="page-9"
                      src="/assets/files/pages/imodium_brochure/0009.jpg"
                      style={{
                        width: "76px",
                        height: "153px"
                      }}
                    />
                    <span>8-9</span>
                  </li>
                  <li className="i">
                    <img
                      className="page-10"
                      src="/assets/files/pages/imodium_brochure/0010.jpg"
                      style={{
                        width: "76px",
                        height: "154px"
                      }}
                    />
                    <img
                      className="page-11"
                      src="/assets/files/pages/imodium_brochure/0011.jpg"
                      style={{
                        width: "76px",
                        height: "153px"
                      }}
                    />
                    <span>10-11</span>
                  </li>
                  <li className="i">
                    <img
                      className="page-12"
                      src="/assets/files/pages/imodium_brochure/0012.jpg"
                      style={{
                        width: "76px",
                        height: "154px"
                      }}
                    />
                    <span>12</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        <footer class="footer" 
        style={{"height": "501.006px"}}>
            <div class="row">
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last"><a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
<ul>
<li class="first last"><a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide</a></li>
</ul>
</li>

                    </ul>

                    <ul>
                        <li class="first last"><a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
<ul>
<li class="first"><a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant</a></li>
<li><a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo</a></li>
<li><a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules</a></li>

</ul>
</li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last"><a href="/feiten-over-diarree/">Feiten over diarree</a>
<ul>
<li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
<li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
<li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
<li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
<li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
<li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
<li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
<li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
<li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
</ul>
</li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        
                    </ul>
                    <ul>
                        <li class="first last"><a href="/diarree-op-reis/">Diarree op reis</a>
<ul>
<li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
<li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
<li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
</ul>
</li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
<li class="here"><a href="/infomateriaal-diaree/">Infomateriaal</a>
<ul>
<li class="first"><a href="/infomateriaal-diaree/patientenbrochure/">Patiëntenbrochure</a></li>
</ul>
</li>
<li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
<li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>

                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
<li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
<li><a href="/privacybeleid/">Privacybeleid</a></li>
<li><a href="/cookiebeleid/">Cookiebeleid</a></li>
<li class="last"><a href="/contact/">Contact</a></li>
<a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.<br/>
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.<br/>
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2200031.</p>


                    </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
